import React from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CreateTemplate from '../components/create/CreateTemplate'
import Content, { HTMLContent } from '../components/Content'

export const CreateContainer = ({
  content,
  contentComponent,
  description,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section>
      {helmet || ''}

      <article>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {description}
        </Typography>
        <Divider />
        <PostContent content={content} />
      </article>
    </section>
  )
}


CreateContainer.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const Create = ({data}) => {
  const { markdownRemark: post } = data

  return (
    <CreateTemplate>
      <CreateContainer
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Literacy Plan">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </CreateTemplate>
  )
}

export default Create;

export const createPageQuery = graphql`
  query CreateByID($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
      }
    }
  }
`
