import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import Fab from '@material-ui/core/Fab';

const styles = {
  root: {
    padding: 6,
  },
  tabs: {
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
  },
  mobiletabs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
  },
  fab: {
    marginRight: 6,
  },
  mobilefab: {
    marginBottom: 6,
  },
  sizeSmall: {
    width: 24,
    height: 24,
    minHeight: 30,
    minWidth: 30,
  },
  icon: {
   fontSize: 16,
  },
  line: {
    width: 1,
    height: '100%',
    background: '#bbb',
    position: 'absolute',
    top: 0,
    left: 20,
    zIndex: -1,
  }
};

const menu = [
  {label: 'Get started', to: '/create'},
  {label: 'Define', to: '/create/define'},
  {label: 'Mission & vision', to: '/create/goals'},
  {label: 'Curriculum & instruction', to: '/create/instruction'},
  {label: 'Assessment', to: '/create/assesment'},
  {label: 'Professional development', to: '/create/teachers'},
  {label: 'Parent & community', to: '/create/home'},
  {label: 'Library', to: '/create/library'},
  {label: 'Evaluate', to: '/create/evaluate'},
];

const MobileStepper = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div className={classes.mobiletabs}>
        {menu.map( (item, i) => (
          <Fab
            key={item.label}
            size="small"
            color="primary"
            aria-label="check"
            component={Link}
            to={item.to}
            className={classes.mobilefab}
            >
            <b>{i + 1}</b>
          </Fab>
        ))}
      </div>
    </div>
  )
}
MobileStepper.propTypes = {
  classes: PropTypes.object.isRequired,
};

class OrdinaryStepper extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.tabs}>
          {menu.map( (item, i) => (
            <Button key={item.label} component={Link} to={item.to}>
              <Fab
                size="small"
                color="primary"
                aria-label="check"
                className={classes.fab}
                classes={{
                  root: classes.fab,
                  sizeSmall: classes.sizeSmall,
                }}
                >
                <b className={classes.icon}>{i + 1}</b>
              </Fab>
              {item.label}
            </Button>
          ))}
          <div className={classes.line}></div>
        </div>
      </div>
    )
  }
}

OrdinaryStepper.propTypes = {
  classes: PropTypes.object.isRequired,
};


const Stepper = (props) => {
  const { classes } = props;
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <div>
    {matches ? <OrdinaryStepper classes={classes} /> : <MobileStepper classes={classes} />}
    </div>
  )
}

Stepper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Stepper);
