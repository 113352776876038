import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Layout from '../Layout'
import Stepper from './Stepper'
import Header from '../Header'

const styles = {
  wrapper: {
    display: 'flex',
  },
  side: {
    borderRight: '1px solid #eee',
    padding: '0 6px',
  },
  content: {
    padding: '0 12px',
    flexGrow: 1,
  }
};

class CreateTemplate extends React.Component {
  render(){
    const { children, classes } = this.props;

      return (
        <Layout
          header={ <Header title="Create a plan" /> }
          content={
            <div className={classes.wrapper}>
              <section className={classes.side}>
                <Stepper />
              </section>
              <section className={classes.content}>
                {children}
              </section>
            </div>
          }
        />
      )
  }
}

export default withStyles(styles)(CreateTemplate);
